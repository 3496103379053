<template>
	<div class="main-qa-content">
		<ion-header color="clear">
			<ion-icon class="questions-title" slot:icon-only :icon="chatbubbles"></ion-icon>QUESTIONS AND ANSWERS
		</ion-header>
		<div class="questions-list" v-if="questions" id="myQuestions">
			<div v-for="question in questions" :key="question.id">
				<ion-list v-if="question.rejected === false" lines="none">
					<ion-item class="allQuestions">
						<ion-avatar slot="start">
							<img v-if="!question.user.avatar_url" :src="require(`@/assets/user.png`)" />
							<img v-else :src="`${backendUrl}${question.user.avatar_url}`" />
						</ion-avatar>
						<ion-label>
							<p class="ion-text-wrap alignLeft">
								<strong>{{ question.user.fullname || question.user.username }}</strong></p>
							<p class="ion-text-wrap alignLeft" v-html=question.body.body></p>
						</ion-label>
						<ion-button fill="clear" @click="sendQuestionLike(question.id)" shape="round" color="warning"
							:disabled="question.user.email === user.email">
							<ion-icon :icon="thumbsUp" slot="icon-only"></ion-icon>
						</ion-button>
						{{ question.number_of_votes }}
					</ion-item>
					<ion-item class="allAnswers" v-if="question.answer.body != '' || question.live === true">
						<ion-label>
							<p v-if="question.answerer" class="ion-text-wrap alignRight">
								<strong>{{ question.answerer.fullname || question.answerer.username }}</strong></p>
							<p class="ion-text-wrap alignRight" v-if="!question.live" v-html="question.answer.body"></p>
							<p class="ion-text-wrap alignRight" v-else>[ RISPOSTA IN LIVE ]</p>
						</ion-label>
						<ion-avatar slot="end">
							<img v-if="!question.answerer || !question.answerer.avatar_url"
								:src="require(`@/assets/user.png`)" />
							<img v-else :src="`${backendUrl}${question.answerer.avatar_url}`" />
						</ion-avatar>
					</ion-item>
				</ion-list>
			</div>
		</div>
		<div class="newQuestion">
			<ion-item>
				<ion-input type="text" placeholder="Domanda" clear-input @keypress="detectReturn($event.keyCode)"
					v-model="questionsInfo.newQuestion">
				</ion-input>
				<ion-button fill="clear" @click="sendMessage()" slot="end" shape="round" color="warning">
					<ion-icon :icon="paperPlane" slot="icon-only"></ion-icon>
				</ion-button>
			</ion-item>
		</div>
	</div>
</template>

<style scoped>
	.main-qa-content {
		display: flex;
		flex-direction: column;
		justify-content: left;
		align-items: top;
		width: 100%;
		height: 100%;
		background-color: whitesmoke;
		position: relative;
	}

	.questions-title {
		margin-right: .3em;
		padding-right: .3em;
		border-right: 2px solid var(--ion-color-primary);
	}

	.questions-list {
		overflow: auto;
		height: 75%;
	}

	.newQuestion {
		overflow: hidden;
		height: 15%;
		margin-left: 3%;
		margin-right: 3%;
	}

	.alignLeft {
		text-align: left;
		margin-right: 3%;
	}

	.alignRight {
		text-align: right;
		margin-left: 3%;
	}

	.allAnswers {
		--ion-background-color: rgb(239, 217, 196);
		border-bottom: 2px solid var(--ion-color-primary);
	}

	ion-list {
		margin-left: 3%;
		margin-right: 3%;
	}

	.allQuestions {
		border-bottom: 2px solid var(--ion-color-primary);
	}

	ion-header {
		margin-left: 1%;
		margin-top: 1%;
		color: var(--ion-color-primary);
		height: 10%;
	}

	/* @media screen and (min-width: 880px) {
		.newQuestion {
			position: absolute;
			bottom: 0;
			width: 45%;
		}
	} */

	@media screen and (max-width: 800px) {
		.newQuestion {
			height: auto;
		}
	}
</style>


<script>
	import {
		backendUrl
	} from "../plugins/variables.js"
	import {
		IonList,
		IonItem,
		IonAvatar,
		IonLabel,
		IonButton,
		IonIcon,
		IonInput,
		IonHeader
	} from "@ionic/vue";
	import {
		thumbsUp,
		paperPlane,
		chatbubbles
	} from "ionicons/icons";
	import {
		defineComponent
	} from "vue";
	import {
		getUserInfo,
		checkUserSession
	} from "../plugins/common.js";
	export default defineComponent({
		components: {
			IonList,
			IonItem,
			IonAvatar,
			IonLabel,
			IonButton,
			IonIcon,
			IonInput,
			IonHeader
		},
		props: {
			defaultAvatar: {
				type: String,
				default: "@/assets/user.png", //"../assets/empty_image.png", //"@/assets/logo_application.png",
			},
		},
		data: () => ({
			backendUrl,
			thumbsUp,
			paperPlane,
			chatbubbles,
			checkUserSession,
			currentId: null,
			user: null,
			questions: null,
			questionsInfo: {
				intervalTick: 10000,
				updateInterval: null,
				lastUpdatedAt: "",
				newQuestion: null,
			},
		}),
		watch: {
			$route() {
				// console.log("Getting Route?")
				// console.log(this.$route)
				// console.log("Watching route")
				// console.log(this.questionsInfo.updateInterval)
				if (this.questionsInfo.updateInterval) this.stopUpdateQuesions();
				else if (this.$route.name == "Play" && this.$route.query.controller == "events") {
					this.user = getUserInfo();
					this.currentId = this.$route.params.id;
					//this.getQuestionsSmart();
					this.startUpdateQuesions();
				}
			}
		},
		created() {},
		mounted() {
			this.user = getUserInfo();
			this.currentId = this.$route.params.id;
			this.getQuestionsSmart();
			this.startUpdateQuesions();
		},
		methods: {
			detectReturn(keyCode) {
				if (keyCode == 13) this.sendMessage();
			},
			getQuestionsSmart() {
				let currSearch = {
					q: {
						event_id_eq: this.currentId,
						s: "updated_at",
						g: [{
							m: "or",
							public_eq: true,
							user_id_eq: this.user.id,
						}, ],
					},
				};
				this.$store
					.dispatch("tables/jsonSearch", {
						model: "questions",
						data: currSearch
					})
					.then((data) => {
						// console.log(data)
						this.questions = data;
						if (data.length > 0) {
							if (
								data[data.length - 1].updated_at >
								this.questionsInfo.lastUpdatedAt
							) {
								this.questionsInfo.lastUpdatedAt =
									data[data.length - 1].updated_at;
								setTimeout(this.scrollQuestionsBar, 500);
							}
						}
					}).catch((error) => {
						this.checkUserSession(error);
					})
			},
			startUpdateQuesions() {
				this.questionsInfo.updateInterval = setInterval(
					this.getQuestionsSmart,
					this.questionsInfo.intervalTick
				);
			},
			stopUpdateQuesions() {
				clearInterval(this.questionsInfo.updateInterval);
				this.questionsInfo.updateInterval = null
			},
			scrollQuestionsBar() {
				var myDiv = document.getElementById("myQuestions");
				myDiv.scrollTop = myDiv.scrollHeight;
			},
			sendMessage() {
				if (this.questionsInfo.newQuestion.length > 0) {
					let currQuestion = {
						question: {
							public: false,
							event_id: this.currentId,
							user_id: this.user.id,
							answer: "",
							body: "<div>"
								.concat(this.questionsInfo.newQuestion)
								.concat("</div>"),
						},
					};
					this.$store
						.dispatch("tables/create", {
							model: "questions",
							data: currQuestion
						})
						.then(() => {
							this.questionsInfo.newQuestion = null;
							this.getQuestionsSmart();
						}).catch((error) => {
							this.checkUserSession(error);
						})
				}
			},
			sendQuestionLike(questId) {
				let currVote = {
					vote: {
						question_id: questId,
						user_id: this.user.id,
					},
				};
				this.$store
					.dispatch("tables/create", {
						model: "votes",
						data: currVote
					})
					.then(() => {
						this.questionsInfo.newQuestion = null;
						this.getQuestionsSmart();
					}).catch((error) => {
						this.checkUserSession(error);
					})
			},
		},
	});
</script>