<template>
	<div class="user-customer-bar" v-if="user">
		<div class="customer-logo">
				<img :src="require(`@/assets/customer_logo.png`)" />
		</div>
		<div class="user-area" slot="end">
			<div>
				<div class="user-info-image">
					<img class="avatar" :src="!user.avatar_url ? require(`@/assets/user.png`) : user.avatar_url"
						alt="Avatar" />
				</div>
				<div class="user-info">
					<div>
						<strong>{{ welcome }}</strong>
					</div>
					<div>{{ user.fullname || user.username }}</div>
					<div>
						<small>
							<router-link to="/login">
								LOGOUT
							</router-link> |
							<a v-if="user.admin" :href="backendURLWithRelativePath">
								BACKEND
							</a>
						</small>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else>{{$router.push(publicAccess ? "/" : "/login")}}</div>
</template>

<style scoped>
	/* HEADER START */
	#customer-logo {
		padding-left: 0 !important;
	}

	.user-customer-bar,
	.customer-logo,
	.customer-logo img {
		height: max(6vw, 3.5em);
	}

	.toolbar-container {
		min-height: auto !important;
	}

	.user-customer-bar {
		position: relative;
		display: flex;
	}

	.user-customer-bar>div:last-child {
		margin-left: auto;
	}

	.customer-logo {
		margin-left: .3em;
	}

	.customer-logo img {
		padding-top: .3em;
		padding-bottom: .3em;
		/* margin-left: 1em; */
	}

	.user-area {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: smaller;
		background-color: var(--ion-color-tertiary);
		padding-left: 2vw;
		padding-right: 2vw;
		height: 100%;
	}

	.user-info-image {
		display: inline-block;
		padding-top: .3em;
		margin-right: .2em;
		margin-left: .2em;
	}

	.user-info {
		display: inline-block;
		color: white;
		/* font-size: 16px; */
		margin-left: .5em;
		padding-top: .3em;
		padding-bottom: .3em;
	}

	.user-info div {
		font-size: max(.9em, 1vw);
		text-align: left;
	}

	.avatar {
		width: max(3.3vw, 3em);
		height: max(3.3vw, 3em);
		border-radius: 50%;
		border: 2px solid white;
	}

	small {
		font-weight: bold;
	}

	/* Tablets */
	/* @media screen and (min-width: 1400px) {

		.user-customer-bar,
		.customer-logo,
		.customer-logo img,
		.user-area {
			height: 86px;
		}

		.avatar {
			width: 47px;
			height: 47px;
		}

		.user-info div {
			font-size: 14px;
		}

		.user-area {
			padding-left: 28.5px;
			padding-right: 28.5px;
		}
	} */

	/* HEADER END */
</style>

<script>
	import {
		// IonButtons,
		// IonToolbar,
		// IonMenuButton,
		// IonTitle,
		// IonContent,
		// IonHeader,
		// IonItem,
		// IonList,
		// IonMenu
	} from "@ionic/vue";

	import {
		defineComponent
	} from "vue";
	import {
		getUserInfo
	} from "../plugins/common.js";
	import {
		backendURLWithRelativePath,
		publicAccess
	} from "../plugins/variables.js"

	export default defineComponent({
		components: {
			// IonButtons,
			// IonToolbar,
			// IonMenuButton,
			// IonTitle,
			// IonContent,
			// IonHeader,
			// IonItem,
			// IonList,
			// IonMenu
		},
		data() {
			return {
				user: null,
				backendURLWithRelativePath,
				publicAccess
			};
		},
		props: {
			customerLogo: {
				type: String,
				default: "./assets/customer_logo.png", //"@/assets/customer_logo.png",
			},
			welcome: {
				type: String,
				default: "Benvenuto", //"Benvenuto",
			}
		},
		watch: {
			$route() {
				this.user = getUserInfo();
			}
		},
		created() {
			this.user = getUserInfo();
		},
		methods: {
		}
	});
</script>